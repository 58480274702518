import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Img from 'gatsby-image'
import {
  NavBar,
  NavBarBrand,
  NavBarBrandImage,
  NavBarContainer,
  NavBarLeft,
  NavBarMenuButton,
  NavBarMobile,
  NavBarMobileContainer,
  NavBarPrimary,
  NavBarRight,
  NavMobile,
  NavbarHedingImg,
  SubNavBarRight,
  SubNavBarItem,
} from '../styled/navBar'
import { MainLinksLeft } from './mainLinksLeft'
import { MainLinksRight } from './mainLinksRight'

const MainHeader = ({ onDropDownPageSelectionChange, selectedPage }) => {
  const [navIsOpen, setNavIsOpen] = useState(false)
  const toggleNav = () => setNavIsOpen(!navIsOpen)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "bilxtra-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function ScrollToTopOnMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return null
  }

  return (
    <React.Fragment>
      <NavBar>
        <NavBarContainer>
          <NavBarBrandImage onClick={ScrollToTopOnMount}>
            <Link to="/">
              <Img fluid={data.logo.childImageSharp.fluid} alt="BilXtra verksted" />
            </Link>
          </NavBarBrandImage>
          <NavBarLeft>
            <MainLinksLeft
              selectedPage={selectedPage}
              onDropDownPageSelectionChange={onDropDownPageSelectionChange}
            />
          </NavBarLeft>

          <NavBarRight>
            <MainLinksRight />
          </NavBarRight>
        </NavBarContainer>

        <NavBarPrimary>
          <SubNavBarRight>
            <SubNavBarItem>
              <Link className="jobehososs" to="/job-vacancy">
                JOBBE HOS OSS
              </Link>
            </SubNavBarItem>
          </SubNavBarRight>
        </NavBarPrimary>
      </NavBar>

      <NavBarMobile>
        <NavBarMobileContainer>
          <NavBarBrand>
            <Link to="/">
              <NavbarHedingImg>
                <Img fluid={data.logo.childImageSharp.fluid} />
              </NavbarHedingImg>
            </Link>
          </NavBarBrand>
          <NavBarRight>
            <NavBarMenuButton onClick={toggleNav}>
              <div />
              <div />
              <div />
            </NavBarMenuButton>
          </NavBarRight>
        </NavBarMobileContainer>

        <NavMobile className={navIsOpen ? 'isOpen' : 'isClosed'}>
          <MainLinksLeft
            selectedPage={selectedPage}
            onDropDownPageSelectionChange={onDropDownPageSelectionChange}
          />

          <MainLinksRight />
        </NavMobile>
      </NavBarMobile>
    </React.Fragment>
  )
}

export default MainHeader
