/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { device } from '../device'

const arrayMove = require('array-move')
const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  z-index: 1;
`

const ListItem = styled.div`
  text-align: left;
  transition: all 0.25s ease-out;
  min-width: 160px;
  //color: var(--primary-color);

  li {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.1rem;
    z-index: 1;
    color: var(--primary-color);
    cursor: pointer;

    border-bottom: 1px solid var(--primary-color);
    @media only screen and ${device.tabletMaxL} {
      border-bottom: 1px solid #dddddd;
    }
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05);
    }
  }
`
export const SelectHedingDropdown = ({ selected, onChange }) => {
  const data = useStaticQuery(graphql`
    query CollectionPageQuery3 {
      allCollectionPageHierarchy {
        edges {
          node {
            name
            page_id
            parent_id
            level
            hidden
          }
        }
      }
    }
  `)

  const {
    allCollectionPageHierarchy: { edges },
  } = data

  const parentPages = edges.map((edge) => edge.node).filter((page) => page.level === 1)

  const array1 = arrayMove(parentPages, 11, 0)
  const toggleChildren = (parent) => {
    selectedPage(parent)
    window.location.href = '/#oversikt'
  }
  const selectedPage = (page) => {
    onChange({ page })
  }

  return (
    <Wrapper>
      {array1
        .filter((parent) => !parent.hidden)
        .map((parent) => (
          <ListItem>
            <a onClick={() => toggleChildren(parent)}>{parent.name}</a>
          </ListItem>
        ))}
    </Wrapper>
  )
}
