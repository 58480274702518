import React from 'react'
import styled from 'styled-components'
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image'
import hedingimage1 from '../images/Atlanterhavsveien_hovedside1.png'
import hedingimage2 from '../images/Bosch-oversikt_hovedside2.png'
import hedingimage3 from '../images/bilxtra_hovedside3.png'
import hedingimage4 from '../images/Elbil_hovedbilde4.png'

import { device } from './device'

const Holderslider = styled.div`
  height: 55vh;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  @media ${device.tabletMaxL} {
    display: none;
  }
  img {
    z-index: -1;
    width: 100%;
    height: 55vh;
    object-fit: cover;
    object-position: center;
    @media ${device.laptopL} {
      margin-top: 140px;
    }
  }
`
//
const Hero = () => {
  const fadeImages = [hedingimage1, hedingimage2, hedingimage3, hedingimage4]
  return (
    <Holderslider>
      <Fade pauseOnHover={false}>
        <div className="each-fade">
          <img src={fadeImages[0]} alt="Car-img" key="0" style={{ width: '100%' }} />
        </div>
        <div className="each-fade">
          <img src={fadeImages[1]} alt="Car-img" key="1" style={{ width: '100%' }} />
        </div>
        <div className="each-fade">
          <img src={fadeImages[2]} alt="Car-img" key="3" style={{ width: '100%' }} />
        </div>
        <div className="each-fade">
          <img src={fadeImages[3]} alt="Car-img" key="4" style={{ width: '100%' }} />
        </div>
      </Fade>
    </Holderslider>
  )
}

export default Hero
