import React, { useState } from 'react'
import { NavBarItem, NavDropDown, NavDropDownContent } from '../styled/navBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { SelectHedingDropdown } from '../collection-page/SelectHedingDropdown'
import { Link } from 'gatsby'
import useOnclickOutside from 'react-cool-onclickoutside'
import styled from 'styled-components'

const Compholder = styled.div`
  position: relative;
  z-index: 1;
`

export const MainLinksLeft = ({ selectedPage, onDropDownPageSelectionChange }) => {
  const [filter, setFilter] = useState(selectedPage)

  const [openMenu, setOpenMenu] = useState(false)
  const [openMenu2, setOpenMenu2] = useState(false)
  const [openMenu3, setOpenMenu3] = useState(false)
  const handleBtnClick = () => setOpenMenu(!openMenu)
  const handleBtnClick2 = () => setOpenMenu2(!openMenu2)
  const handleBtnClick3 = () => setOpenMenu3(!openMenu3)
  const closeMenu = () => setOpenMenu(false)
  const closeMenu2 = () => setOpenMenu2(false)
  const closeMenu3 = () => setOpenMenu3(false)
  const ref = useOnclickOutside(() => closeMenu())
  const ref2 = useOnclickOutside(() => closeMenu2())
  const ref3 = useOnclickOutside(() => closeMenu3())
  //s
  return (
    <React.Fragment>
      <NavDropDown className="dropdown" ref={ref}>
        <NavBarItem className="dropdown-btn" onClick={handleBtnClick}>
          <Link to="/#">
            Finn verksted
            <FontAwesomeIcon icon={faSortDown} style={{ float: 'right', margin: '3px' }} />
          </Link>
        </NavBarItem>
        {openMenu && (
          <NavDropDownContent className="dropdown-menu" onClick={closeMenu}>
            <Compholder>
              <SelectHedingDropdown
                selected={filter}
                onChange={({ page }) => {
                  setFilter(page)
                  console.log(setFilter(page))
                  onDropDownPageSelectionChange(page)
                }}
              />
            </Compholder>
          </NavDropDownContent>
        )}
      </NavDropDown>

      <NavDropDown className="dropdown" ref={ref2}>
        <NavBarItem className="dropdown-btn" onClick={handleBtnClick2}>
          <Link to="/#">
            Fordeler & garanti
            <FontAwesomeIcon icon={faSortDown} style={{ float: 'right', margin: '3px' }} />
          </Link>
        </NavBarItem>
        {openMenu2 && (
          <NavDropDownContent className="dropdown-menu" onClick={closeMenu2}>
            <Link to="/mulighet-bilxtra/Veihjelp">Veihjelp</Link>
            <Link to="/mulighet-bilxtra/delegaranti">Delegaranti</Link>
            <Link to="/mulighet-bilxtra/Fritt-verkstedvalg">Fritt verkstedvalg</Link>
            <Link to="/mulighet-bilxtra/bilxtra-konto">BilXtra Konto</Link>
            <Link to="/mulighet-bilxtra/Digitalservicehefte">Digitalservicehefte</Link>
            <Link to="/#omoss">Om Bilxtra</Link>
          </NavDropDownContent>
        )}
      </NavDropDown>
      <NavDropDown className="dropdown" ref={ref3}>
        <NavBarItem className="dropdown-btn" onClick={handleBtnClick3}>
          <Link to="/#">
            Service & reparasjon
            <FontAwesomeIcon icon={faSortDown} style={{ float: 'right', margin: '3px' }} />
          </Link>
        </NavBarItem>
        {openMenu3 && (
          <NavDropDownContent className="dropdown-menu" onClick={closeMenu3}>
            <Link to="/service-og-reparasjon/EU-kontroll">EU kontroll</Link>
            <Link to="/service-og-reparasjon/air-condition"> AC Service og Klimaservice</Link>
            <Link to="/service-og-reparasjon/hjulstillingskontroll">Hjulstillingskontroll</Link>
            <Link to="/service-og-reparasjon/el-bil">El-bil & hybrid</Link>
            <Link to="/service-og-reparasjon/reparasjon-av-alle-bilmerker">
              Reparasjon av alle bilmerker
            </Link>
            <Link to="/service-og-reparasjon/motor-og-service">Motor – service</Link>
            <Link to="/service-og-reparasjon/clutch-og-brems">Clutch og brems</Link>
            <Link to="/service-og-reparasjon/diagnose-og-systemscan">Diagnose og systemscan</Link>
            <Link to="/service-og-reparasjon/Oljeskift">Oljeskift</Link>
            <Link to="/service-og-reparasjon/reparasjon-av-tilhengere">
              Reparasjon av tilhengere
            </Link>
          </NavDropDownContent>
        )}
      </NavDropDown>
      <NavBarItem>
        <Link to="/service-og-reparasjon/el-bil">El-bil & hybrid</Link>
      </NavBarItem>
    </React.Fragment>
  )
}
