import React from 'react'
import styled from 'styled-components'
import { device } from './device'

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  width: 50%;
  max-width: 1600px;

  z-index: 1;

  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  @media (max-width: 1041px) {
    grid-template-columns: auto;
    grid-gap: 25px;
  }

  @media ${device.tabletMaxL} {
    top: 80px;
    position: relative;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 25px;
    margin-bottom: 50px;
    justify-content: center;
  }

  @media ${device.laptop} {
    display: flex;
    width: 100%;
    margin-top: 21px;
    justify-content: center;
    #search,
    #pkk {
      flex: 1;
    }
    #search {
      margin-right: 1rem;
      padding-bottom: 1.8rem;
    }
    #pkk {
      margin-left: 1rem;
    }
  }

  @media (min-width: 1440px) {
    margin-top: 150px;
  }
  @media (max-width: 1030px) {
    margin-top: 21px;
  }
  @media (max-width: 764px) {
    margin-top: 85px;
  }
`

const HeroOptions = ({ children }) => {
  return <Options>{children}</Options>
}

export default HeroOptions
