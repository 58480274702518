import React from 'react'
import { ButtonBilxtra, NavBarItem } from '../styled/navBar'
import { Link } from 'gatsby'
//import { useLogout } from '../../hooks/use-logout'
//import { useGetToken } from '../../hooks/use-get-token'

export const MainLinksRight = () => {
  /*
  const token = useGetToken()

  const { logout } = useLogout()

  const doLogout = async () => {
    await logout()
    await navigate(`/`)
  }*/

  return (
    <React.Fragment>
      <NavBarItem>
        <Link to="/login">Bileiers side</Link>
      </NavBarItem>
      <NavBarItem>
        <ButtonBilxtra>
          <a href="https://www.bilxtra.no" target="_blank" rel="noreferrer">
            Bilxtra.no
          </a>
        </ButtonBilxtra>
      </NavBarItem>
    </React.Fragment>
  )
}
