import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { useWorkshops } from '../hooks/use-workshops'
import { generateWorkshopPagePrefix } from '../url-helpers'

const HeroOption = styled.div`
  display: block;
  max-width: 500px;
  min-width: 300px;
  z-index: 1;
  justify-content: center;
  padding: 1.8rem 1rem;
  color: white;
  background: var(--primary-color);

  h5 {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  input {
    font-size: 1rem;
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 0.3rem;
    border: none;
    z-index: 1;
    border-radius: 2px;
  }

  form {
    display: flex;

    input {
      flex: 1;
    }

    button {
      font-size: 100%;
      padding: 0.5rem 1rem;
      border: none;
    }
  }
`

const AutoComplete = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const AutoCompleteList = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 29.5px;
  width: 100%;
  color: #333333;
  background: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;
`

const AutoCompleteListItem = styled.li`
  padding: 1rem;
  border-bottom: 1px solid #dddddd;
  transition: background 0.25s ease-out;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  :hover {
    cursor: pointer;
    background: #f6f6f6;
  }
`

const findWorkshopsConnectedToPages = (matchingPages, workshops) => {
  const pageIds = matchingPages.map((p) => p.page_id)

  return Array.from(
    new Set(
      pageIds
        .map((pageId) => workshops.filter((w) => w.collection_pages.indexOf(pageId) !== -1))
        .flatMap((w) => w)
    )
  )
}

const FindWorkshopHeroOption = ({ collectionPages }) => {
  const [filter, setFilter] = useState('')
  const [matchingPages, setMatchingPages] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const workshops = useWorkshops()

  useEffect(() => {
    if (!filter) {
      setMatchingPages([])
      return
    }

    const timeout = setTimeout(() => {
      const pageHasFilterInName = (cp) => cp.name.toLowerCase().startsWith(filter.toLowerCase())
      setMatchingPages(collectionPages.filter(pageHasFilterInName))
      setIsSearching(false)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setIsSearching(false)
    }
  }, [filter, setMatchingPages, setIsSearching, collectionPages])

  const includesFilterInName = (workshop) =>
    workshop.name.toLowerCase().includes(filter.toLowerCase())

  const matchingWorkshops = findWorkshopsConnectedToPages(matchingPages, workshops)

  const allWorkshops = filter
    ? Array.from(new Set([...workshops.filter(includesFilterInName), ...matchingWorkshops]))
    : []

  return (
    <HeroOption id="search">
      <h5>Finn BilXtra verksted</h5>
      <AutoComplete>
        <input
          type="text"
          name="search"
          placeholder="Søk på navn, poststed eller fylke"
          onChange={({ target: { value } }) => setFilter(value)}
        />
        <AutoCompleteList>
          {isSearching && []}
          {allWorkshops.length > 0 &&
            allWorkshops.map((workshop, i) => {
              const prefix = generateWorkshopPagePrefix(workshop)

              return (
                <AutoCompleteListItem key={`ws-${i}`}>
                  <Link to={`/${prefix}/${workshop.slug}`}>{workshop.name}</Link>
                </AutoCompleteListItem>
              )
            })}
        </AutoCompleteList>
      </AutoComplete>
    </HeroOption>
  )
}

export default FindWorkshopHeroOption
