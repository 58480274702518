import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import MainHeader from '../components/main-navigation/mainHeader'
import './layout.css'
import Hero from '../components/hero'
import NextPkkHeroOption from '../components/nextPkkHeroOption'
import FindWorkshopHeroOption from '../components/findWorkshopHeroOption'
import HeroOptions from '../components/heroOptions'
import Footer from '../components/footer'
import ScrollTop from '../components/scrollTop'
import SelectWorkshopModal from '../components/selectWorkshopModal'
import { Layout } from '../components/styled/layout'
import { generateWorkshopPagePrefix } from '../url-helpers'

const MainLayout = ({ collectionPages, children, onDropDownPageSelectionChange }) => {
  const [showWorkshopSelector, setShowWorkshopSelector] = useState(false)
  //newtest
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      {showWorkshopSelector && (
        <SelectWorkshopModal
          onClose={() => setShowWorkshopSelector(false)}
          onSelected={async (workshop) => {
            const prefix = generateWorkshopPagePrefix(workshop)
            await navigate(`/${prefix}/${workshop.slug}#Bestilltime`)
          }}
        />
      )}
      <MainHeader
        siteTitle={data.site.siteMetadata.title}
        onDropDownPageSelectionChange={onDropDownPageSelectionChange}
      />

      <Hero />
      <HeroOptions>
        <FindWorkshopHeroOption collectionPages={collectionPages} />
        <NextPkkHeroOption
          orderButtonClicked={() => {
            setShowWorkshopSelector(true)
          }}
        />
      </HeroOptions>

      <main>{children}</main>

      <ScrollTop />

      <Footer />
    </Layout>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
